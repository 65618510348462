.privacyPolicySection h4 {
  margin: 1rem 0;
}

.backButtonIcon {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  border: none;
  background-position: center;
  background: url("../../assets/icons/left_arrow.svg");
  width: 26px;
  height: 26px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 85px;
}

.searchResultContainer {
  margin-top: 1.5rem;
}

.searchResultHeader {
  padding: 0 0.75rem;
}

.searchResultTitle {
  font-family: "Poppins";
  display: inline-block;
  font-size: 20px;
  color: #212121;
  position: relative;
  top: -6px;
  left: 20px;
}

.resultSection {
  margin-left: 110px;
  position: relative;
  top: 20px;
}

.logo {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  border: none;
  background-position: center;
  display: inline-block;
}

.backIcon {
  background: url("../../assets/icons/left_arrow.svg");
  width: 26px;
  height: 26px;
  cursor: pointer;
}

.resultSectionCarLogo {
  background: url("../../assets/icons/car.svg");
  width: 65px;
  height: 35px;
  margin: 15px 0 0 0px;
}

.resultSectionUserLogo {
  background: url("../../assets/icons/user.svg");
  width: 50px;
  height: 50px;
  margin: 6px 0 0 0px;
}

.resultSectionLienLogo {
  background: url("../../assets/icons/lien_logo.svg");
  width: 60px;
  height: 50px;
  margin: 8px 0 0 0px;
}

.resultSectionLessorLogo {
  background: url("../../assets/icons/deal.png");
  width: 60px;
  height: 50px;
  margin: 8px 0 0 0px;
}

.resultSectionTitle {
  font-size: 22px;
  color: #673ab7;
  font-weight: 600;
  font-family: "Poppins";
}

.labelName {
  font-size: 13px;
  color: #757575;
  font-weight: 400;
  font-family: "Open sans";
}

.labelValue {
  font-size: 15px;
  color: #000000;
  font-weight: 400;
  font-family: "Open sans";
  text-transform: capitalize;
}

.dualContainer {
  padding-left: 12px;
}

.resultColumnTitleRow {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.resultColumnTitleText {
  width: 20% !important;
}

.resultColumnTitleIcon {
  width: 20% !important;
}

.resultOwnerColumn {
  width: 10% !important;
}

.resultLessorColumn {
  width: 13% !important;
}

@media screen and (max-width: 465px) {
  .resultSectionTitle {
    margin-left: 20px;
  } 
}

@media screen and (max-width: 600px) {
  .resultSection {
    margin-left: 45px;
  }
}

@media screen and (min-width: 600px) {
  .resultSectionTitle {
    margin-left: 15px;
  }

  .resultSection {
    margin-left: 90px;
  }
}

@media screen and (min-width: 1200px) {
  .resultSection {
    margin-left: 110px;
  }
}
.searchTitle {
  color: #673ab7;
  font-size: 36px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 60px;
}

.searchSection {
  background: #f4f6f7;
  width: 100%;
  padding: 20px;
}

.searchSection .row .searchSectionCol > div {
  margin: 0px 20px;
}

.searchSectionSelect > div {
  padding: 0 30px 0 0;
}

.DPPAcheckbox {
  margin-top: 10px !important;
  /* color: #2196f3; */
  font-family: "Open sans";
}

.searchBtn {
  width: 100%;
  font-weight: 600 !important;
  text-transform: uppercase;
}

.iconsSection {
  width: 100%;
  text-align: right;
  padding-top: 20px;
}

.iconsSection div {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width: 22px;
  height: 22px;
  border: none;
  cursor: pointer;
  display: inline-block;
}

.uploadIcon {
  background: url("../../assets/icons/upload_icon.svg");
  margin-right: 15px;
}

.printIcon {
  background: url("../../assets/icons/print_icon.svg");
  margin-right: 15px;
}

.shareIcon {
  background: url("../../assets/icons/share_icon.svg");
}

.uploadIcon:hover {
  background: url("../../assets//icons/upload_icon_purple.svg");
  margin-right: 15px;
}

.DPPAsection > div {
  display: inline-block;
}

.DPPAsection {
  height: 38px;
}

.DPPAsectionCheckBox input {
  position: relative;
  top: -8px;
  left: -15px;
}

.DPPAsectionTextLabel {
  line-height: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}

.DPPAsectionTextSubLabel {
  font-size: 11px;
  font-weight: 400;
  color: #2196f3;
  cursor: pointer;
  text-align: left;
}

.checkBoxDiv {
  background: white;
}

@media screen and (max-width: 970px) {
  .width30 {
    width: 100% !important;
  }

  .width25 {
    width: 100% !important;
  }

  .width15 {
    width: 100% !important;
  }

  .searchSectionSelect > div {
    padding: 0;
  }

  .searchSection .row .searchSectionCol > div {
    margin: 10px 0;
  }
}

@media screen and (min-width: 970px) {
  .width30 {
    width: 25% !important;
  }

  .width25 {
    width: 25% !important;
  }

  .width15 {
    width: 25% !important;
  }

  .searchSection .row .searchSectionCol > div {
    margin: 0 10px;
  }
}

@media screen and (min-width: 1024px) {
  .width30 {
    width: 30% !important;
  }

  .width25 {
    width: 25% !important;
  }

  .width15 {
    width: 15% !important;
  }
}

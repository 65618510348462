.loginContainer {
  height: 100vh;
  background-color: #f7f5f5 !important;
  background-position: center !important;
  background-size: cover !important;
}

.login-wrapper {
  height: 100vh;
  padding: 120px 0 0 0;
  /* opacity: 0.2; */
}

.logoImg {
  background: url("../../assets/images/logo.png");
  background-position: center !important;
  background-size: contain !important;
  background-repeat: no-repeat;
  width: 175px;
  height: 48px;
  position: absolute;
  top: 36px;
  left: 50px;
  cursor: pointer;
}

.image-wrapper {
  height: 100vh;
  background-color: white;
  padding-top: 185px;
}

.image-wrapper div {
  background: url("../../assets/images/loginpage-img.jpg");
  background-position: center !important;
  background-size: contain !important;
  background-repeat: no-repeat;
  width: 100%;
  height: 57%;
  margin: auto;
}

.loginTitle {
  /* margin: 0 180px 10px 0; */
  font-family: "Poppins";
  font-size: 36px;
  font-weight: 600;
  text-align: center;
  color: #622cc0;
}

.loginSubtitle {
  margin: 10px 37.3px 30px 0;
  font-family: "Open Sans";
  font-size: 18px;
  text-align: left;
  color: #212121;
}

.loginSection {
  margin: auto;
  width: 50%;
}

.inputControls {
  margin-bottom: 15px;
}

.loginCheckboxLabel {
  font-size: 13px;
}

.link {
  color: #2196f3;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
}

.loginBtn {
  width: 100%;
  font-size: 16px !important;
  font-weight: 600 !important;
  border-radius: 5px;
  text-transform: uppercase;
}

.forgot-password {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  font-family: "Open Sans";
}

.footer-text {
  margin: 30px 67.3px 0 0;
  font-family: "Open Sans";
  font-size: 16px;
  text-align: left;
}

@media screen and (max-width: 1030px) {
  .login-wrapper {
    padding: 220px 0 0 0;
  }

  .loginSection {
    width: 70%;
  }
}

@media screen and (max-width: 764px) {
  .login-wrapper {
    padding: 120px 0 0 0;
  }

  .image-wrapper {
    display: none;
  }

  .logoImg {
    left: 30px;
    width: 150px;
    height: 42px;
  }
}

@media screen and (max-width: 480px) {
  .forgot-password {
    flex-direction: column;
  }

  .link {
    line-height: 30px;
  }
}

@media screen and (max-height: 480px) {
  .image-wrapper {
    height: 430px;
  }

  .loginContainer {
    height: 430px;
  }

  .login-wrapper {
    height: auto;
    padding: 60px 0 10px 0;
    background-color: #f7f5f5 !important;
  }

  .loginTitle {
    font-size: 35px;
    margin: 0;
  }

  .loginSubtitle {
    font-size: 14px;
    margin: 5px 0 10px 0;
  }

  .inputControls {
    margin-bottom: 10px;
  }
}

.apiKeyText {
  font-size: 16px;
  padding-left: 5px;
}

.profilePageCopyIconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 25px;
}

.profilePageCopyIcon {
  background: url("../../assets/icons/copy-outline.svg");
  width: 24px !important;
  height: 24px !important;
  margin-right: 0px !important;
  background-repeat: no-repeat;
  cursor: pointer;
}

/* #main-wrapper {
    height: 100vh;
} */

/* .headerPanel {
    z-index: 2;
} */

.left-sidebar {
  z-index: 4;
  position: fixed;
  width: 245px;
  left: -300px;
}

.left-small-sidebar {
  z-index: 0;
  position: fixed;
}

.sectionContainer {
  display: flex;
}

.row > * {
  padding: 0 !important;
  margin: 0 !important;
}

.page-wrapper {
  margin-top: 50px;
  margin-left: 10px;
  padding: 20px 50px 15px 110px;
}

.show-sidebar .left-sidebar {
  left: 0;
}

.show-sidebar .page-wrapper {
  margin-left: 185px;
}

.btn {
  background-image: linear-gradient(to right, #ea80fc, #673ab7);
}

.btn:hover {
  background: linear-gradient(89.99deg, #9b33ad 0.01%, #673ab7 99.99%);
}

.btn:active {
  background: linear-gradient(89.99deg, #673ab7 0.01%, #673ab7 99.99%);
}

.cancelBtn {
  background: #f8f8f8 !important;
  font-family: "Open Sans";
  color: #757575 !important;
  border: 1px solid #b1b1b1 !important;
}

.cancelBtn:hover {
  background: #ffffff !important;
  border: 1px solid #b1b1b1 !important;
}

.cancelBtn:active {
  background: #ffffff !important;
  border: 1px solid #673ab7 !important;
}

.form-control:focus {
  border: 1px solid #3d85d8 !important;
  outline: none !important;
  box-shadow: none !important;
}

.form-check-input:focus {
  box-shadow: none !important;
}

.form-check-input:checked {
  background-color: #673ab7 !important;
  border-color: #673ab7 !important;
}

.form-check-input[type="checkbox"] {
  border-radius: 2px !important;
}

input[type="checkbox"]{
  cursor: pointer !important;
}

.alert {
  font-family: "Open Sans";
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
}

.pageTitle {
  color: #673ab7;
  font-size: 36px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 60px;
}

.show-mobile-sidebars .mobileMenu {
  display: none;
}

.show-mobile-sidebars .page-wrapper {
  margin-left: 0;
}

.spinner-border-sm {
  width: 1rem !important;
  height: 1rem !important;
  border-width: 0.2em !important;
}

.customTableHeaderCell {
  font-family: "Open Sans" !important;
  font-size: 12px !important;
  text-transform: uppercase;
  color: #757575 !important;
  padding: 16px 16px 5px 16px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
  white-space: nowrap !important;
}

.MuiTableBody-root .MuiTableCell-root {
  padding: 12px 16px !important;
  font-family: "Poppins" !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #606d77;
  text-overflow: ellipsis !important;
  border-bottom: 1px solid #f4f4f4 !important;
  overflow: hidden !important;
}

.MuiTableBody-root .MuiTableRow-root:hover {
  background: #6a86a714 !important;
  background-blend-mode: color !important;
}

/* .css-1ex1afd-MuiTableCell-root {
  border: none !important;
  padding: 12px 16px !important;
  font-family: "Poppins" !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #606d77 !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.css-apqrd9-MuiTableBody-root .css-1q1u3t4-MuiTableRow-root:hover {
  background: #6a86a714 !important;
  background-blend-mode: color !important;
} */

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: #aaadb340 !important;
}

:root {
  scrollbar-color: #aaadb340 rgb(255, 255, 255) !important;
}

.customToastrWidth {
  width: 550px;
}

.normalToastrWidth {
  width: 400px;
}

input::placeholder {
  color: #6c7985;
  font-size: 14px;
  font-family: "Open sans";
}

@media screen and (max-width: 764px) {
  .pageTitle {
    font-size: 32px;
  }

  .page-wrapper {
    padding: 20px 20px;
  }

  .show-sidebar .page-wrapper {
    margin-left: 0;
  }
}

@media screen and (max-width: 430px) {
  .pageTitle {
    line-height: 45px;
  }
}

.appIconSection > div {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width: 19px;
  height: 19px;
  border: none;
  cursor: pointer;
  display: inline-block;
}

.appUploadIcon {
  background: url("../../assets/icons/upload_icon.svg");
  margin-right: 15px;
}

.appRefreshIcon {
  background: url("../../assets/icons/refresh-outline.svg");
  margin-right: 15px;
}

.appPrintIcon {
  background: url("../../assets/icons/print_icon.svg");
  margin-right: 15px;
}

.appFilterIcon {
  background: url("../../assets/icons/filter.svg");
  width: 26px;
  /* margin-left: 10px; */
  height: 26px;
}

.appShareIcon {
  background: url("../../assets/icons/share_icon.svg");
}

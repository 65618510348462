.accountListSection {
  width: 100%;
  padding: 0 0.75rem;
}

.accountsListTable {
  height: calc(100vh - 250px) !important;
}

.actionBtn {
  width: 30%;
  font-size: 16px !important;
  font-weight: 600 !important;
  border-radius: 5px;
  text-transform: uppercase;
}

.accountListIcons div {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width: 22px;
  height: 22px;
  border: none;
  cursor: pointer;
  display: inline-block;
  margin-top: 22px;
}

.accountListIconSection {
  width: 100%;
  white-space: nowrap;
}

.accountListIconSection > div {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width: 22px;
  height: 18px;
  border: none;
  cursor: pointer;
  display: inline-block;
  margin-right: 13px;
}

.smallSearchIcon {
  background: url("../../assets/icons/small_search.png");
  margin-right: 15px;
  width: 20px !important;
  height: 20px !important;
}

.smallFilterIcon {
  background: url("../../assets/icons/filter.svg");
  margin-right: 15px;
}

.smallAddIcon {
  background: url("../../assets/icons/ic-Add.svg");
}

.smallEditIcon {
  background: url("../../assets/icons/edit-2-outline.svg");
}

.smallDeleteIcon {
  background: url("../../assets/icons/trash-2-outline.svg");
}

.smallResendInviteIcon {
  background: url("../../assets/icons/email-outline.svg");
  width: 23px !important;
}

.smallBillAccountIcon {
  background: url("../../assets/icons/bill_logo.svg");
  width: 23px !important;
  height: 20px !important;
}

.smallViewSearchIcon {
  background: url("../../assets/icons/sidebar/documents-search.svg");
  width: 23px !important;
  height: 20px !important;
}

.copyIconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 25px;
  cursor: pointer;
}

.copyIcon {
  background: url("../../assets/icons/copy-outline.svg");
  width: 25px !important;
  height: 25px !important;
  margin-right: 0px !important;
  background-repeat: no-repeat;
}

.copyText {
  font-family: "Poppins" !important;
  font-size: 13px !important;
  color: #606d77 !important;
  padding-top: 15px;
}

.disableContainer {
  cursor: default;
  opacity: 0.5;
}

.companyNameColumn {
  font-family: "Poppins" !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #2196f3 !important;
  text-transform: capitalize;
  cursor: pointer;
  white-space: nowrap;
}

.portalUrlColumn {
  font-family: "Poppins" !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #2196f3 !important;
  cursor: pointer;
  white-space: nowrap;
}

.companyNameColumn span {
  display: block;
  font-family: "Poppins" !important;
  font-size: 10px !important;
  color: #98a0a7 !important;
}

.contactPersonColumn {
  white-space: nowrap;
}

.contactPersonColumn span {
  display: block;
  font-family: "Poppins" !important;
  font-size: 10px !important;
  color: #98a0a7 !important;
}

.listSearchBar {
  text-align: end !important;
}

.listSearchBar > div {
  display: inline-block;
}

.searchInput {
  position: relative;
  bottom: 12px;
  margin-right: 15px;
}

.statusColumn {
  text-transform: uppercase !important;
}

.statusFilterField {
  width: 300px;
}

.appFilterIcon {
  background: url("../../assets/icons/filter.svg");
  width: 25px;
  margin-right: 10px;
  height: 25px;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  cursor: pointer;
}

.filterModalTitle {
  text-align: left !important;
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #673ab7 !important;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.crossDeleteIconFilterModal {
  background: url("../../assets/icons/close\ 1.svg");
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width: 14px;
  height: 14px;
  border: none;
  cursor: pointer;
  display: block;
  float: right;
}

@media screen and (max-width: 991px) {
  .accountListIconSection {
    width: 100%;
    white-space: nowrap;
  }

  .searchInput {
    width: 75%;
  }
}

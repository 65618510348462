.successScreenMsg {
  font-family: "Poppins";
  font-size: 22px;
  text-align: center;
  color: #212121;
}

.successScreenBtn {
  width: 100%;
  font-weight: bold !important;
  font-size: 18px !important;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.successScreenIcon {
  background-position: center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width: 150px;
  height: 100px;
  margin: 70px auto 0 auto;
}

.successScreenSuccessIcon {
  background: url("../../assets/icons/success_screen.svg");
}

.successScreenErrorIcon {
  background: url("../../assets/icons/alert-triangle-outline.svg");
  margin: 50px auto;
}

.successScreenBtn {
  width: auto;
  padding: 0.375rem 1.75rem !important;
}

@media screen and (max-width: 768px) {
  .successScreenMsg {
    font-size: 18px;
  }
}

@keyframes loading {
  40% {
    background-position: 100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.loading {
  position: relative;
}

.loading .bar {
  background-color: #e1e1e1;
  height: 14px;
  width: 80%;
}

.loading:after {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 0;
  content: "";
  display: block;
  width: 100%;
  height: 24px;
  background-image: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0) 80%
  );
  background-size: 200px 24px;
  background-position: -100px 0;
  background-repeat: no-repeat;
  animation: loading 1s infinite;
}

.section-title {
  font-size: 24px !important;
}

.DPPAsection > div {
  display: inline-block;
}

.DPPAsection {
  height: 38px;
}

.DPPAsectionCheckBox input {
  position: relative;
  top: -8px;
  left: -15px;
}

.DPPAsectionTextLabel {
  line-height: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #212121;
}

.DPPAsectionTextSubLabel {
  font-size: 11px;
  font-weight: 400;
  color: #2196f3;
  cursor: pointer;
  text-align: left;
}

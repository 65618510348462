.batchPreviewTitle {
  font-family: "Poppins";
  display: inline-block;
  font-size: 20px;
  color: #212121;
  position: relative;
  top: -6px;
}

.batchPreviewBtns button {
  display: inline-block;
  margin-right: 20px;
  width: 15%;
  font-size: 16px !important;
  font-weight: 600 !important;
  border-radius: 5px;
  text-transform: uppercase;
}

.batchPreviewAlert {
  position: relative;
  padding-left: 70px !important;
  top: 0;
  left: 0;
  right: 0;
}

.batchPreviewPlateCell > div {
  display: inline-block;
}

.batchPreviewPlateText {
  font-family: "Poppins" !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #212121 !important;
  text-transform: capitalize;
  position: relative;
  top: -3px;
}

.greenText {
  font-family: "Poppins" !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #33a521 !important;
}

.redText {
  font-family: "Poppins" !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #f44336 !important;
}

.crossRedIcon {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width: 14px;
  height: 19px;
  border: none;
  cursor: pointer;
  background: url("../../assets/icons/cross-red.svg");
}

.batchPreviewTitleBtn {
  display: inline-block;
  position: relative;
  bottom: 6px;
}

.batchPreviewTitleSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 465px) {
  .batchPreviewBtns button {
    display: block;
    margin: 10px 0;
    width: 100%;
  }
}

@media screen and (max-width: 670px) {
  .batchPreviewTitleSection {
    flex-direction: column;
    align-items: flex-start;
  }
}

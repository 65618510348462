.customNavTab {
  margin-top: 1rem;
  border-bottom: 1px solid #75757533;
}

.customNavTab button {
  font-family: "Poppins";
  font-size: 14px;
  color: #212121;
  width: 172px !important
}

.customNavTab button.Mui-selected {
  color: #212121;
}
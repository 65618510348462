.batchSearchHeader {
  padding: 0 0.75rem;
}

.batchSearchTitle {
  display: inline-block;
  font-size: 20px;
  color: #212121;
}

.batchSearchContainer {
  margin: auto !important;
  height: 190px;
  background: rgba(103, 58, 183, 0.08);
  border: 4px dotted #673ab7;
  cursor: pointer;
}

.batchSearchDiv {
  position: relative;
  top: 23%;
  left: 30%;
  width: 300px;
}

.batchSearchIcon {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width: 110px;
  height: 110px;
  border: none;
  cursor: pointer;
  background: url("../../assets/icons/drag-drop-file.svg");
  position: relative;
  top: -60px;
  left: -30px;
}

.smallUploadIconBatchSearch {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width: 25px;
  height: 25px;
  border: none;
  cursor: pointer;
  background: url("../../assets/icons/upload_icon_purple.svg");
}

.batchSearchDivTitle > div {
  display: inline-block;
}

.batchSearchTextTitle {
  font-size: 15px;
  color: #000000;
  font-weight: 400;
  font-family: "Open sans";
}

.batchSearchSubTitle {
  font-size: 13px;
  color: #757575;
  font-weight: 400;
  font-family: "Open sans";
}

.batchSearchText {
  margin-left: 15px;
}

.sampleInputFileText {
  margin-left: 40px;
}

.sampleInputFileText span {
  color: #2196f3;
}

.sampleFileLink {
  font-size: 13px !important;
}

.batchSearchSection {
  margin-top: 3rem;
}

.batchSampleFileBtn {
  margin-left: 13%;
}

@media screen and (max-width: 465px) {
  .batchSearchDiv {
    width: 240px;
    left: 25%;
  }

  .batchSearchSection {
    margin-top: 1rem;
  }

  .batchSearchIcon {
    width: 95px;
    height: 95px;
    left: -25px;
  }

  .batchSampleFileBtn {
    margin-left: 15%;
  }
}

.userMenuContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0 10px 0 0;
}

.userMenuImage {
  background: url("../../../assets/icons/user_profile.svg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  margin-right: 5px;
  /* Change style to below after changing icon */
  /* width: 1.75rem;
    height: 1.75rem; */
  /* border: none;
    border-radius: 50%; */
}

.userMenuText {
  margin-left: 0.5rem;
  font-size: 15px;
  font-family: "Open Sans";
}

.hiddenMenu {
  visibility: hidden;
}

.menuBar {
  position: absolute;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  pointer-events: auto;
  top: 36px;
  right: 25px;
  transform: translateY(15px);
  background-color: #ffffff;
}

.menu-item {
  margin: 0;
  padding: 0;
  list-style: none;
  cursor: pointer;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}

.menu-item li {
  color: #222b45 !important;
  font-family: Open Sans, sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem;
  border-bottom: 1px solid #f4f4f4;
}

.menu-item li:hover {
  background-color: #f7f4f4b5;
}

.menu {
  display: flex;
  border-radius: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding: 0.75rem 1rem;
  align-items: center;
}

/* .menu:hover {
  color: #598bff;
} */

.menuIcon {
  background-size: cover;
  background-repeat: no-repeat;
  border: none;
  margin: 0 1rem 0 0;
  width: 18px;
  height: 18px;
  line-height: 1;
  text-align: center;
}

.menuLogoutIcon {
  background: url("../../../assets/icons/log-out-outline.svg");
}

.menuProfileIcon {
  background: url("../../../assets/icons/person-outline.svg");
}

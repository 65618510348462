.autocompleteInput > div {
  padding: 0 !important;
}

.autocompleteInput > div.Mui-disabled input {
  padding: 0 !important;
  background: #f8f8f8 !important;
  -webkit-text-fill-color: #323840 !important;
  font-size: 15px;
  color: #323840;
  padding: 0 12px !important;
}

.autocompleteInput > div:hover fieldset {
  border: 1px solid #bdbdbd !important;
}

.autocompleteInput > div.Mui-focused {
  padding: 0 !important;
  /* border: 1px solid #673ab7 !important; */
}

.autocompleteInput > div.Mui-focused fieldset {
  border: 1px solid #673ab7 !important;
}

.autocompleteInput > div.Mui-focused fieldset:hover {
  /* padding: 0 !important; */
  border: 1px solid #bdbdbd;
}

.autocompleteInput input {
  height: 38px;
  background: white;
  font-size: 15px;
  color: #323840;
  padding: 0 12px !important;
}

.autocompleteInput > label.Mui-focused {
  line-height: 22px;
  color: #673ab7;
  font-size: 18px;
  padding-right: 5px;
  background: white;
  top: 0;
}

.autocompleteInput > label.Mui-disabled {
  background: #f8f8f8 !important;
}

.autoCompleteHaveValue > label {
  top: 0 !important;
  line-height: 22px;
  font-size: 18px !important;
  padding-right: 5px;
  background: white;
}

.autocompleteInput > label {
  color: "#6c7985";
  font-size: 14px;
  font-family: "Open sans";
  line-height: 22px;
  padding-right: 3px;
  background: white;
  top: -8px;
}

.customAutocompleteField[error="error"] input {
  border-bottom: 2px solid #ca190c;
}

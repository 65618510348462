.batchResultHeader,
.batchResultList {
  padding: 0 0.75rem;
}

.batchResultTitle {
  font-family: "Poppins";
  display: inline-block;
  font-size: 20px;
  color: #212121;
  position: relative;
  top: -6px;
  left: 20px;
}

.licensePlateColumn {
  font-family: "Poppins" !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #2196f3 !important;
  text-transform: capitalize;
  cursor: pointer;
}

.licenseNumberText {
  font-weight: bold !important;
  position: relative;
  top: -3px;
}

.licensePlateCell > div {
  display: inline-block;
}

.tableCellSubValue {
  display: block;
  font-family: "Poppins" !important;
  font-size: 10px !important;
  color: #98a0a7 !important;
  white-space: nowrap;
}

.smallCarIcon {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width: 30px;
  height: 16px;
  border: none;
  cursor: pointer;
  background: url("../../assets/icons/small-car.svg");
  margin-right: 10px;
}

.tableCellValue {
  font-family: "Poppins" !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #212121 !important;
  white-space: nowrap;
}

.addUserSection {
  width: 100%;
  padding: 0 20px 20px 20px;
}

.sectionTitle {
  font-size: 22px;
}

.addUserBtns div {
  display: inline-block;
  width: 35%;
  margin-right: 20px;
}

.addUserBtn {
  width: 100%;
  font-size: 16px !important;
  font-weight: 600 !important;
  border-radius: 5px;
  text-transform: uppercase;
}

.addUserCheckboxLabel {
  font-family: "Open sans";
  font-size: 15px;
  line-height: 20px;
  color: #323840;
  margin-bottom: 0 !important;
  opacity: 1 !important;
}

/* .addUserSelectInput #demo-simple-select-helper {
  margin: 0 !important;
} */

@media screen and (max-width: 768px) {
  .addUserSection {
    padding: 0 20px !important;
  }

  .addUserInputs div {
    margin: 0;
  }

  .addAccountSmallInputs div {
    margin: 0;
  }
}

@media screen and (min-width: 768px) {
  .addUserInputs div {
    margin: 0 25px 0 0;
  }
}

@media screen and (min-width: 1024px) {
  .addUserInputs div {
    margin: 0 110px 0 0;
  }

  .addAccountSmallInputs div {
    margin: 0 15px 0 0 !important;
  }

  .zipInput div {
    margin: 0 !important;
  }
}

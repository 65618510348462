.list_of_all_users {
  display: none;
  list-style: circle;
  transition: all 0.3s linear;
  display: flex;
  align-items: center;

  .dot {
    height: 5px;
    width: 5px;
    background-color: #696868;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
}

.dateFilterSection {
  width: 100%;
  padding: 0 20px 20px 20px;
}

.endDatePicker {
  margin: 0 3rem !important;
}

@media screen and (max-width: 767px) {
  .endDatePicker {
    margin: 20px 0 0 0 !important;
  }
}

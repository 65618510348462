.dateSelectContainer {
  width: 100% !important;
}

.customDatePickerInput .MuiOutlinedInput-root {
  height: 31px;
  background: white;
  font-size: 15px;
  color: #323840;
}

.customDatePickerInput input {
  padding-bottom: 14.5px !important;
}

.customDatePickerInput .MuiOutlinedInput-root:focus {
  outline: none;
  border: 1px solid #673ab7;
}

.customDatePickerInput:hover fieldset {
  border: 1px solid #bdbdbd !important;
}

.customDatePickerInput .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #673ab7 !important;
  border-width: 1px !important;
}

.customDatePickerInput .MuiOutlinedInput-root:focus label {
  color: #673ab7;
}

.customDatePickerInput label {
  color: #6c7985;
  font-size: 16px;
  font-family: "Open sans";
  background: white;
  position: absolute;
  top: -5px;
  left: -5px;
  padding: 0 5px;
}

/* .customDatePickerInput label:focus {
  color: #6c7985;
  background: white;
} */

.header-container {
    position: fixed;
    top: 0;
    background: white;
    right: 0;
    left: 0;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.sidebar-btn {
    background: url('../../assets/icons/menu_icon1.svg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 30px;
    height: 25px;
    border: none;
    margin-right: 10px;
    cursor: pointer;
}

.logoContainer {
    background: url('../../assets/images/logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 93px;
    height: 32px;
    border: none;
    cursor: pointer;
}

@media screen and (max-width: 1024px) {
    .logoContainer {
        width: 95px;
        height: 31px;
    }
}
.dppaModal {
  max-width: 60% !important;
}

.dppaModalDescription h4 {
  margin: 1rem 0;
}

.dppaDescriptionTitle {
  display: block;
  font-size: 1em;
  margin-top: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.customDppaTooltip .tooltip-inner {
  max-width: 800px !important;
  text-align: start;
}

#DisabledAutoHideExample {
  color: #2196f3;
  cursor: pointer;
  text-decoration: underline;
}

@media screen and (max-width: 465px) {
  .dppaModal {
    max-width: 90% !important;
    margin: 0.5rem auto !important;
  }
}
.batchHistoryHeader,
.batchHistoryList {
  padding: 0 0.75rem;
}

.batchHistoryTitle {
  font-family: "Poppins";
  display: inline-block;
  font-size: 20px;
  color: #212121;
}

.batchFileColumn {
  font-family: "Poppins" !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #2196f3 !important;
  cursor: pointer;
}

.batchFileNameText {
  font-weight: bold !important;
  position: relative;
  top: -3px;
}

.batchFileCell {
  white-space: nowrap;
}

.batchFileCell > div {
  display: inline-block;
}

.smallFileIcon {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width: 14px;
  height: 19px;
  border: none;
  cursor: pointer;
  background: url("../../assets/icons/file-icon.svg");
  margin-right: 10px;
}

.totalRecordCell {
  color: #2196f3 !important;
  cursor: pointer;
  font-family: "Poppins" !important;
  font-size: 14px !important;
  line-height: 19px !important;
}

.threeDotIcon {
  background: url("../../assets/icons/more-vertical-outline.svg");
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width: 19px;
  height: 19px;
  border: none;
  cursor: pointer;
  display: inline-block;
}

.disableColumn {
  cursor: default !important;
  color: #212121 !important;
}

.batchListFilterSection {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.batchListFilterInput {
  width: 65%;
  margin-right: 10px;
  position: relative;
  top: -10px;
}
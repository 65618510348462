.appLoaderContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.loaderBackdrop {
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  -webkit-tap-highlight-color: transparent;
  z-index: 1001000;
}

.css-1szo49z-MuiCircularProgress-root {
  width: 65px !important;
  height: 65px !important;
}

.ReviewPageSection {
  background: #f4f6f7;
  width: 100%;
  margin: auto;
  padding: 20px;
}

.fileUploadContainer {
  margin: auto !important;
  width: 40%;
  /* height: 100px; */
  background: rgba(103, 58, 183, 0.08);
  border: 4px dotted #673ab7;
  cursor: pointer;
}

.fileUploadTitleSection {
  text-align: center;
  margin: 20px 0;
}

.fileUploadTitleSection > div {
  display: inline-block;
}

.fileUploadTextTitle {
  font-size: 15px;
  color: #000000;
  font-weight: 400;
  font-family: "Open sans";
}

.fileUploadSubTitle {
  font-size: 13px;
  color: #757575;
  font-weight: 400;
  font-family: "Open sans";
}

.fileUploadText {
  margin-left: 15px;
}

.originalTracerDownloadBtn {
  display: inline-block;
}

@media screen and (max-width: 540px) {
  .originalTracerDownloadBtn {
    margin-bottom: 10px;
  }
}
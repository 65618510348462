.did-floating-label-content {
  position: relative;
}

.did-floating-label {
  color: #6c7985;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  font-family: "Open sans";
  pointer-events: none;
  left: 8px;
  top: 9px;
  padding: 0 5px;
  background: white;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.did-floating-input {
  font-size: 14px;
  display: block;
  width: 100%;
  height: 38px;
  padding: 0 12px;
  background: white;
  color: #323840;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid #ced4da;
}

input[error="error"] {
  border-bottom: 2px solid #CA190C;
}

.did-floating-input:focus[error="error"] {
  border-bottom: 2px solid #CA190C;
}

.did-floating-input:disabled {
  background: #F8F8F8;
  border: 1px solid #BDBDBD;
}

.did-floating-input:focus {
  outline: none;
  border: 1px solid #673ab7;
}

.did-floating-input:focus {
  outline: none;
  border: 1px solid #673ab7;
}

.did-floating-input:focus ~ .did-floating-label {
  top: -8px;
  font-size: 12px;
  color: #673ab7;
}

.did-floating-input:not(:placeholder-shown) ~ .did-floating-label {
  top: -8px;
  font-size: 13px;
}

.input-group .did-floating-input {
  border-radius: 0 4px 4px 0;
  border-left: 0;
  padding-left: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  background-color: #ffffff !important;
  color: #323840 !important;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -webkit-text-fill-color: #323840 !important;
}

.errorMsg {
  font-size: 13px;
  padding-left: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.iconContainerInput {
  text-align: end;
}
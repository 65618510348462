.sadIconImage {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  border: none;
  background-position: center;
  background: url("../../assets/icons/sad-face.svg");
  width: 150px;
  height: 150px;
  margin: auto;
}

.notFoundPage section {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.notFoundPageTitle {
  margin-top: 0rem;
  font-size: 5rem;
  font-weight: bold;
  font-family: "Poppins";
  line-height: 70px;
  color: #757575;
  margin-top: 20px;
}

.notFoundPageSubTitle {
  font-size: 2rem;
  font-weight: bold;
  color: #b9b8b8c4;
}

.notFoundPagePara {
  color: #b9b8b8;
  margin-top: 20px;
}

.searchHomeContainer {
  margin-top: 5rem;
}

.iconsContainer {
  text-align: center;
}

.iconsContainer div {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width: 70px;
  height: 70px;
  border: none;
  background-position: center;
  display: inline-block;
}

.iconsContainer div div {
  position: relative;
  top: 72px;
  font-size: 14px;
  text-align: center;
  color: #673ab7;
}

.searchIcon {
  background: url("../../assets/icons/search.svg");
  margin-right: 15px;
}

.resultIcon {
  background: url("../../assets/icons/list.svg");
  margin-right: 15px;
}

.billIcon {
  background: url("../../assets/icons/bill.svg");
}

.arrowIcon {
  background: url("../../assets/icons/right_arrow.svg");
  margin-right: 15px;
  width: 30px !important;
  height: 30px !important;
  position: relative;
  top: 35px;
}

.textContainer {
  font-size: 36px;
  color: #757575;
  font-weight: 400;
  font-family: "Poppins";
  text-align: center;
  margin-top: 50px;
}

.uploadSection {
  width: 55%;
  margin: auto;
  background: rgba(244, 246, 246, 0.5);
  border: 1px dashed #babfc2;
  margin-top: 50px;
  text-align: center;
  padding: 25px;
  cursor: pointer;
}

.uploadSection div {
  display: inline-block;
}

.uploadSectionUploadIcon {
  background: url("../../assets/icons/upload_icon_purple.svg");
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width: 30px;
  height: 30px;
  border: none;
  background-position: center;
  margin-right: 10px;
}

.uploadSectionTextContainer {
  font-size: 16px;
  line-height: 20px;
}

.uploadSectionTextContainer p {
  margin: 0;
  padding: 0;
}

.uploadSectionTextContainer .subTitle {
  font-size: 14px;
  color: #757575dc;
}

@media screen and (max-width: 630px) {
  .searchHomeContainer {
    margin-top: 3rem;
  }

  .iconsContainer div {
    width: 100%;
    margin-bottom: 45px;
  }

  .arrowIcon {
    display: none !important;
  }

  .textContainer {
    margin-top: 0;
  }

  .uploadSectionUploadIcon {
    margin-right: 10px;
  }
}

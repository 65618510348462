.left-sidebar,
.left-small-sidebar {
  background: #ffffff;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  height: 100vh;
}

.nav-link {
  color: black !important;
  padding: 0.7rem 1rem;
}

.nav-item {
  padding: 5px 0 5px 0;
  border-bottom: 1px solid #edf1f7;
}

.collapsibleNav .nav-item {
  padding: 5px 0 5px 10px;
}

.nav-item:hover,
.active {
  cursor: pointer;
  background: #f7f4f4b5;
}

.mainNav > .nav-item:first-child {
  margin-top: 25px;
}

.sidebarIcon {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  /* background-position: center; */
  width: 100%;
  height: 21px;
  border: none;
  cursor: pointer;
  display: inline-block;
}

.sidebarArrowIcon {
    background-size: contain !important;
    background-repeat: no-repeat !important;
    /* background-position: center; */
    width: 17px;
    height: 17px;
    position: absolute;
    right: 10px;
    /* border: none;
    cursor: pointer; */
}

.sidebarText {
  /* margin-left: 65px; */
  margin-left: 35px;
  /* color: #222b45; */
  font-family: Open Sans, sans-serif;
  font-size: 0.8125rem;
  font-weight: 600;
  /* line-height: 1.5rem; */
  color: #222b45;
}

.mobileMenu {
  width: 70px !important;
}

.mobileMenu .nav-item {
  padding: 0 !important;
}

.mobileMenu .sidebarIcon {
  background-position: center;
}

@media screen and (max-width: 764px) {
  .mobileMenu {
    display: none;
  }

  /* .show-sidebar .mobileMenu {
        display: block !important;
    } */
}

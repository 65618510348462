.centralLoader {
  background: url("../../assets/icons/Misterious\ mist.gif");
  background-position: center !important;
  background-size: contain !important;
  background-repeat: no-repeat;
  width: 150px;
  height: 100px;
  margin: 70px auto 0 auto;
}

.resetPasswordContainer {
  width: 50%;
  margin: 120px auto 0 auto;
}

.resetPasswordHeader div {
  margin-bottom: 30px;
}

.resetPasswordHeader .title {
  font-family: "Poppins";
  font-size: 38px;
  font-weight: 600;
  text-align: center;
  color: #622cc0;
}

.resetPasswordHeader .subTitle {
  font-family: "Poppins";
  font-size: 22px;
  text-align: center;
  color: #212121;
}

.resetPasswordSection {
  width: 55%;
  margin: auto;
}

.resetPassCheckboxLabel {
  font-family: "Open sans";
  font-size: 15px;
  line-height: 20px;
  /* color: #2196F3; */
  margin-bottom: 0 !important;
  cursor: pointer;
}

.resetPassBtn {
  width: 100%;
  font-weight: bold !important;
  font-size: 18px !important;
  text-transform: uppercase;
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .resetPasswordContainer {
    width: 80%;
    margin: 70px auto 0 auto;
  }

  .resetPasswordHeader .subTitle {
    font-size: 18px;
  }

  .resetPasswordSection {
    width: 90%;
  }
}

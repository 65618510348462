.AppMenuIcon {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width: 22px;
  height: 22px;
  border: none;
  cursor: pointer;
  display: inline-block;
}

.AppMenuHeaderDiv {
  text-align: center !important;
}
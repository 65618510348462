.searchReportHeader,
.searchReportTableRow {
  padding: 0 20px;
}

.searchReportTitle,
.searchReportTotalCountTitle {
  font-family: "Poppins";
  display: inline-block;
  font-size: 20px;
  color: #212121;
}

.searchReportTotalCountTitle {
  font-size: 24px;
  color: #673ab7;
}

.billListPlateCell {
  cursor: default !important;
}

.searchReportInput {
  bottom: 5px !important;
}

@media screen and (max-width: 991px) {
  .searchReportInput {
    margin: 10px 0 !important;
    bottom: 0 !important;
  }
}

.accessDeniedImage {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  border: none;
  background-position: center;
  background: url("../../assets/icons/access-denied.png");
  width: 135px;
  height: 135px;
  margin: auto;
}

.accessDenied section {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.accessDeniedTitle {
  margin-top: 0rem;
  font-size: 5rem;
  font-weight: bold;
  font-family: "Poppins";
  line-height: 70px;
  color: #757575;
  margin-top: 20px;
}

.accessDeniedSubTitle {
  font-size: 2rem;
  font-weight: bold;
  color: #b9b8b8c4;
}

.accessDeniedPara {
  color: #b9b8b8;
  margin-top: 20px;
}

.modalTitle {
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  text-align: center !important;
  color: #673ab7 !important;
}

.modalBtns {
  text-align: center;
}

.modalBtns button {
  display: inline-block;
  width: 30%;
  margin-right: 20px;
}

.crossDeleteIcon {
  background: url("../../assets/icons/close\ 1.svg");
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width: 18px;
  height: 18px;
  border: none;
  cursor: pointer;
  display: block;
  float: right;
}

@media screen and (max-width: 600px) {
  .modalBtns button {
    display: block;
    width: 100%;
    margin: 10px 0;
  }
}
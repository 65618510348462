.inputSearchIcon {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width: 18px;
  height: 18px;
  border: none;
  cursor: pointer;
  display: inline-block;
}

.selectContainer {
  width: 100% !important;
}

.customIconAppInput:hover fieldset {
  border: 1px solid #bdbdbd !important;
}

.customIconAppInput.Mui-focused fieldset {
  border: 1px solid #673ab7 !important;
}

.iconAppInputHaveNoValue fieldset {
  top: 0px !important;
} 

.iconAppInputHaveNoValue legend {
  display: none !important;
}
.accountStatsList {
  padding: 0 20px;
}

.billSearchHistoryTitle,
.billSearchTotalCountTitle {
  font-family: "Poppins";
  display: inline-block;
  font-size: 20px;
  color: #212121;
}

.billSearchTotalCountTitle {
  font-size: 24px;
  color: #673ab7;
}

.billListPlateCell {
  cursor: default !important;
}

.billSearchInput {
  bottom: 5px !important;
}

.statsLink {
  color: #2196f3 !important;
  cursor: pointer;
}

.accountStatsIconSection {
  position: relative;
  top: 20px;
}

@media screen and (max-width: 991px) {
  .billSearchInput {
    margin: 10px 0 !important;
    bottom: 0 !important;
  }

  .accountStatsIconSection {
    position: relative;
    top: 0;
  }
}

.userListSection {
  width: 100%;
  padding: 0 0.75rem;
}

.actionBtn {
  width: 30%;
  font-size: 16px !important;
  font-weight: 600 !important;
  border-radius: 5px;
  text-transform: uppercase;
}

.userListIconSection > div {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width: 22px;
  height: 22px;
  border: none;
  cursor: pointer;
  display: inline-block;
}

.smallSearchIcon {
  background: url("../../assets/icons/small_search.png");
  margin-right: 15px;
  width: 20px !important;
  height: 20px !important;
}

.smallFilterIcon {
  background: url("../../assets/icons/filter.svg");
  margin-right: 15px;
}

.smallAddIcon {
  background: url("../../assets/icons/ic-Add.svg");
}

.smallEditIcon {
  background: url("../../assets/icons/edit-2-outline.svg");
}

.companyNameColumn {
  font-family: "Poppins" !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #2196f3 !important;
  text-transform: capitalize;
}

.companyNameColumn span {
  display: block;
  font-family: "Poppins" !important;
  font-size: 10px !important;
  color: #98a0a7 !important;
}

.dateAddedColumn {
  white-space: nowrap;
}

.emailValue {
  text-transform: unset !important;
}

.userEmail {
  text-transform: lowercase !important;
}

.disableIcon {
  opacity: 0.5;
  cursor: default !important;
}

.userListTable {
  height: calc(100vh - 250px) !important;
}
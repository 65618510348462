.drawer-paper {
  width: 100%;
}

@media (min-width: 992px) {
  .drawer-paper {
    width: 75%;
  }
}

@media (min-width: 1200px) {
  .drawer-paper {
    width: 50%;
  }
}

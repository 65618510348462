.appAlertContainer {
  position: fixed;
  top: 48px;
  left: 70px;
  right: 0;
  z-index: 500;
  font-family: 'Open Sans' !important;
  background: #FFF1A8 !important;
  color: #000000 !important;
}

.show-sidebar .changeAlertWidth {
  left: 245px !important;
}

.alertIcon {
  width: 13px;
  height: 13px;
  position: relative;
  top: 3px;
}

.alertMessageIcon {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width: 1rem;
  height: 1rem;
  border: none;
  position: relative;
  top: 3px;
  display: inline-block;
}

@media screen and (max-width: 764px) {
    .appAlertContainer {
        left: 0;
    }

    .show-sidebar .changeAlertWidth {
      left: 0 !important;
    }
}
.selectContainer {
  width: 100% !important;
}

.customSelectField:hover fieldset {
  border: 1px solid #bdbdbd !important;
}

.customSelectField.Mui-focused fieldset {
  border: 1px solid #673ab7 !important;
}

.selectError {
  border-bottom: 2px solid #ca190c;
}

.selectErrorMsg {
  font-size: 13px !important;
  padding-left: 5px !important;
  margin: 0 !important;
  font-family: unset !important;
}

#demo-simple-select-helper {
  margin: 0 !important;
}

#demo-simple-select-helper-label .Mui-focused {
  color: #673ab7;
  top: -4px;
}
